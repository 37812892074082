.root {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 60px);
  padding: 0px 10px;

  .editor {
    display: flex;
    flex-grow: 1;

    .bee {
      width: 100%;
    }
  }

  .buttons {
    padding-top: 10px;
  }
}

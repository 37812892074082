@import '../../../../../_variables.sass';

.control {
  flex: 0 0 calc(100% - 40px);
  overflow-x: auto;
}

.input {
  padding: 0 11px !important;
  max-width: 100%;
  overflow-x: auto;

  .btn {
    display: block;
    flex: 1 0 auto;
    border-radius: 16px !important;
    padding: 0 8px;
    overflow: hidden;
    max-width: 150px;

    span {
      display: block;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-align: left;
    }
  }

  .internalInput,
  .internalInput:active,
  .internalInput:hover,
  .internalInput:focus,
  .internalInput:focus-visible,
  .internalInput:focus-within {
    padding: 7px 0;
    border: none;
    box-shadow: none;
    height: 38px;
  }
}

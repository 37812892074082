.link {
  text-decoration: none;
}

.globalAxiosProgress {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 0.3rem !important;
}

.root {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  .field {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .control {
      flex-grow: 1;
      display: flex;

      .textArea {
        flex-grow: 1;
      }
    }
  }
}

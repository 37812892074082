@import '../../_variables.sass';

.error-marker {
  color: black;
  width: 10px !important;
  height: 10px;
  border-radius: 50%;
  background-color: #ff0000;
  margin-top: 6px;
  margin-left: 4px;
}

.error-marker .error-message {
  display: none;
  position: absolute;
  background-color: $grey-lighter;
  border: 1px solid $grey-light;
  padding: 6px;
  width: 200px;
  left: 24px;
  top: 0;
}

.error-marker:hover .error-message {
  display: block;
}

.CodeMirror-highlighted {
  display: inline-block;
  position: relative;
}

.CodeMirror-highlighted:before {
  content: "~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~";
  font-size: 10px;
  font-weight: 700;
  font-family: Times New Roman, Serif;
  color: $danger;
  width: 100%;
  position: absolute;
  top: 12px;
  left: -1px;
  overflow: hidden;
}

.autoGrow {
  display: flex;
  flex-grow: 1;

  .CodeMirror {
    height: auto;
    flex-grow: 1;
  }
}


@import './_variables.sass';
@import '~bulma/bulma.sass';
@import '~codemirror/lib/codemirror.css';
@import '~codemirror/addon/lint/lint.css';

*, *:before, *:after {
  box-sizing: border-box;
}

.dropdown-item {
  cursor: pointer !important;
}

.dropdown-item:hover {
  background-color: #EDEDED;
}

@import '../../_variables.sass';

.tabs {
  &:global(.tabs) {
    margin-bottom: 0 !important;

    a {
      border-bottom-width: 1px;
    }
  }
}

.longForm {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.table {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid $grey-lightest;

  .row {
    display: flex;
    flex-direction: row;

    &:not(:last-child) {
      border-bottom: 1px solid $grey-lightest;
    }

    input, select {
      border: none;
      box-shadow: none;
      width: 100%;
      
      &:active, &:focus {
        border: none;
        box-shadow: none;
      }
    }

    .error {
      flex-grow: 1;
      padding: 8px 5px;
      background-color: $grey-lightest;
    }

    .col {
      flex-grow: 1;
      padding: 0;

      &:not(:last-child) {
        flex-grow: 0;
        width: 30%;
        padding: 0px 8px;
        display: flex;
        align-items: center;
        border-right: 1px solid $grey-lightest;
      }

      label {
        display: block;
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
      }
    }
  }
}

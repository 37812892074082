.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  border-top: 3px solid #666;

  .remove {
    position: absolute;
    right: 10px;
  }
}

@import '@x-functions/x-react-workflow/lib/components/workflow/_variables.scss';

$header-height: 57px;
$global-header-height: 0;

.root {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: calc(100vh - #{$global-header-height});
  background-color: $canvas-bg;

  .header {
    height: $header-height;
    display: inline-flex;
    align-items: center;
  }

  .jsonEditor {
    display: flex;
    flex-grow: 1;
    flex-shrink: 1;
    margin-left: 0;
    margin-right: 0;
    overflow-y: hidden;
  }

  .workflow {
    flex-grow: 1;
  }

  .leftCol, .rightCol {
    position: fixed;
    z-index: 10;
    background-color: white;
    width: 0px;
    max-height: calc(100vh - #{$header-height + $global-header-height});
    overflow-y: auto;
    padding: 1px;

    &.expanded {
      width: 350px;
    }
  }

  .rightCol {
    right: 0;

    .expandButton {
      position: fixed;
      right: 0;
    }

    &.controls {
      right: 100px;
    }

    &.expanded {
      .expandButton {
        right: 350px;
      }

      &.controls {
        right: 90px;
        width: fit-content;
      }
    }
  }

  .leftCol {
    left: 0;

    .expandButton {
      position: fixed;
      left: 0;
    }

    &.expanded .expandButton {
      left: 350px;
    }
  }

  .col {
    position: relative;

    .fixed {
      position: fixed;
      width: 25%;
      z-index: 1000;
      background-color: white;
      height: calc(100vh - 52px);
      overflow-y: scroll;
    }
  }

  .body {
    height: calc(100vh - #{$header-height + $global-header-height});
    overflow: hidden;
    display: flex;
    position: relative;
  }
}

.title {
  margin-top: 1em;
}

.webhookDetails {
  width: 100%;
}

.column {
  flex-direction: column;
}

.field {
  width: 100%;
}

@import '../../../../_variables.sass';

.field {
  width: 100%;
}

.panelBlock {
  display: block;
}

.panelColumn {
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
  align-items: center;
  background-color: $white;
  border-bottom: 1px solid $grey-lightest;

  color: $text;

  .tabs {
    margin-bottom: 0;

    :global(.is-active) {
      font-weight: bold;
    }

    ul {
      margin: 0;

      a {
        padding: 0 4px;
      }
    }
  }

  .buttons {
    flex-wrap: nowrap;
  }
}

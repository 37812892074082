@import '../../_variables.sass';

.loading {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    background-color: lighten($grey-lighter, 10%);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }

  &.bar::before {
    background-color: darken($grey-lighter, 10%);
  }

  &::after {
    content: "";
    display: block;
    background-color: $grey-lighter;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    transform: translateX(0);
    :global {
      animation: 1.5s loading-placeholder ease-in-out infinite;
    }
  }

  &.bar::after {
    :global {
      animation: 0.5s loading-placeholder ease-in-out infinite;
    }
  }
}

@keyframes :global(loading-placeholder) {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}


.drawer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -32px;
  width: 0;
  display: none;
  z-index: 2;

  .close {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &.open {
    display: block;
    width: 250px;
    :global {
      animation: 0.5s open-drawer ease-in-out;
    }

    .close {
      display: block;
    }
  }

  &.left {
    left: -32px;
    right: unset;

    .close {
      right: unset;
      left: 10px;
    }
  }
}

@keyframes :global(open-drawer) {
  0% {
    width: 0;
  }
  100% {
    width: 250px;
  }
}

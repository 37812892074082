.sideBar {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
  overflow-y: auto;
  z-index: 1000;
}

.header {
  position: relative;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 2rem;

  .closeButton {
    position: absolute;
    right: 0.75rem;
  }
}


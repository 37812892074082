.root {
  display: flex;
  flex-direction: column;

  .editor {
    flex-grow: 1;
  }

  .buttons {
    padding-top: 10px;
  }
}

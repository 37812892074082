@import '../../_variables.sass';

.versionSection {
  padding-top: 16px;
  padding-bottom: 16px;
  border-top: 1px solid $grey-lightest;

  &.noBorder {
    border-top: none;
  }

  .title {
    padding-left: 16px;
  }
}

.drawer {
  background-color: white;
  border-left: 1px solid $grey-lightest;
  width: 300px;
  overflow-y: auto;
}

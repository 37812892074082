@import '../../_variables.sass';

.row {
  flex-wrap: wrap;
}

.clickable {
  cursor: pointer;

  &:hover {
    background-color: $grey-lighter;
  }
}


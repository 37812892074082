.content {
  width: 100%;

  .card {
    margin-top: 10px;

    &.active {
      background-color: #eaeaea;
    }
  }
}

.tile {
  cursor: pointer;

  .contextMenuButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
  }
}

.contextMenuBody {
  background-color: white;
}

.pad {
  width: 116px;
  position: fixed;
  right: 360px;
  top: calc(100vh - 95px);
  background-color: #fafafa;
  border: 3px solid #eaeaea;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .spacer {
      width: 36px;
      height: 36px;
    }
  }
}

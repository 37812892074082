.root {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  margin: 4px 0;
  margin-bottom: 4px; // accomodate for the border at the bottom

  &:hover {
    color: #666;
    border-color: #666;
  }
}
